class MultiStepForm {
  constructor({ node }) {
    this.form = node;
    this.url = this.form.getAttribute('action');
    this.currentStep = 1;
    this.totalSteps = document.querySelectorAll('div[data-step]').length;

    [...this.form.querySelectorAll('a.next-step')]
      .forEach(btn => btn
          .addEventListener('click', this.handleNext.bind(this)));

    [...this.form.querySelectorAll('a.prev-step')]
      .forEach(btn => btn
        .addEventListener('click', this.gotoPrev.bind(this)));
    
    this.form
      .querySelector('button.submit')
      .addEventListener('click', this.handleSubmit.bind(this));

    this.initStep();

    document.addEventListener('keydown', e => {
      if (e.keyCode === 13) {
        if (this.currentStep !== this.totalSteps) {
          e.preventDefault();
          this.handleNext(e);
        }
      }
    });
  }

  initStep() {
    document.getElementById('recap').innerHTML = '';

    if (this.currentStep === this.totalSteps) {
      const formData = this.getFormData();
      const html = [];

      for (const key of MultiStepForm.RECAP_ORDER) {
          const { getOption, tag } = MultiStepForm.RECAP_FILTER[key];
          const value = getOption ? getOption() : formData.get(key);
          if (value) html.push(`<${tag}>${value}</${tag}> `);
      }

      document.getElementById('recap').innerHTML = html.join('');
    }
  }

  getFormData(last = false) {
    const data = new URLSearchParams();
    if (!last) {
      data.append('step', this.currentStep);
      data.append('submitPartCreate', true);
    }

    for (const pair of new FormData(this.form)) {
      data.append(pair[0], pair[1]);
    }

    return data;
  }

  async handleNext(e) {
    e.preventDefault();
    try {
      this.getActiveStepNode().classList.add('loading');
      const res = await fetch(
        this.url,
        { method: 'post', body: this.getFormData() }
      );
      const { success, errors } = await res.json();
      if (success) {
        this.gotoNext();
      } else {
        this.showErrors(errors);
      }
    } catch (err) {
      this.error();
    }
  }

  showErrors(errors) {
    const node = this.getActiveStepNode().querySelector('.alert');
    this.getActiveStepNode().classList.remove('loading');
    node.classList.add('visible');
    node.querySelector('.alert-danger').innerHTML = `
    <ul>
    ${(
        Object.values(errors)
          .map(error => `<li>${error}</li>`)
          .join('')
      )}
    </ul>
  `;

    window.scrollTo(0, 0);
  }

  emptyErrors() {
    const node = this.getActiveStepNode();
    node.classList.remove('visible');
    node.querySelector('.alert-danger').innerHTML = '';
  }

  getActiveStepNode() {
    return this.form.querySelector(`div[data-step="${this.currentStep}"]`);
  }
  
  gotoNext() {
    window.scrollTo(0, 0);
    this.getActiveStepNode().classList.remove('active');
    this.currentStep += 1;
    this.initStep();
    this.getActiveStepNode().classList.add('active');
  /* recaculate backgrounds */
    if (window.navigator.userAgent.indexOf('MSIE ') > 0) {
      window.dispatchEvent(new Event('resize'));
    } else {
      const resizeEvent = window.document.createEvent('UIEvents');
      resizeEvent.initUIEvent('resize', true, false, window, 0);
      window.dispatchEvent(resizeEvent);
    }
  }

  gotoPrev() {
    window.scrollTo(0, 0);
    this.getActiveStepNode().classList.remove('active');
    this.currentStep -= 1;
    this.getActiveStepNode().classList.add('active');
    this.getActiveStepNode().classList.remove('loading');
  }

  error() {}
  handleSubmit() {}
}

MultiStepForm.RECAP_FILTER = {
  'customer_firstname': {
    tag: 'span'
  },
  'customer_lastname': {
    tag: 'span'
  },
  email: {
    tag: 'div'
  },
  alias: {
    tag: 'h5'
  },
  firstname: {
    tag: 'span'
  },
  lastname: {
    tag: 'span'
  },
  address1: {
    tag: 'div'
  },
  address2: {
    tag: 'div'
  },
  postcode: {
    tag: 'div'
  },
  city: {
    tag: 'div'
  },
  'id_country': {
    tag: 'div',
    getOption: () => {
      const select = document.querySelector('select[name="id_country"]');

      return select.options[select.selectedIndex].text;
    }
  },
  phone: {
    tag: 'div'
  },
  'phone_mobile': {
    tag: 'div'
  }
};

MultiStepForm.RECAP_ORDER = [
  'customer_firstname',
  'customer_lastname',
  'email',
  'alias',
  'firstname',
  'lastname',
  'address1',
  'address2',
  'postcode',
  'city',
  'id_country',
  'phone',
  'phone_mobile'
];

export default MultiStepForm;
