class Parallax {
  constructor(node) {
    this.node = node;
    this.attributes = this.initAttributes();

    if (this.attributes.parallaxY) {
      this.loadImage();
      window.addEventListener('resize', () => this.loadImage());
    }
  }

  initAttributes() {
    this.node.style.backgroundImage = '';
    this.node.style.backfaceVisibility = 'hidden';
    this.node.style.transform = 'transform3d(0,0,0)';

    return ({
      translateY: this.node.getAttribute('data-translate-y') || 0,
      translateX: this.node.getAttribute('data-translate-x') || 0,
      parallaxY: Boolean(this.node.getAttribute('data-parallax-y')) || false,
      url: this.node.getAttribute('data-img')
    });
  }

  loadImage() {
    return new Promise((res, rej) => {
      const img = new Image();
      img.onload = () => res(img);
      img.onerror = rej;
      img.src = this.attributes.url;
    })
    .then(img => {
      this.img = img;
      if (this.node.attributes) {
        this.node.style.backgroundImage = `url(${this.attributes.url})`;
        this.node.style.opacity = 1;
      }
      this.computeImageSize();
    })
    .catch(() => {});
  }

  computeImageSize() {
    const { width, height } = this.node.getBoundingClientRect();
    const imageRatio = this.img.width / this.img.height;
    const containerRatio = width / height;
    const fitWidth = containerRatio > imageRatio;
    this.fitWidth = fitWidth;

    /* grow 30% of container height */
    const delta = height * 0.35;

    /* fit image to container */
    let w = fitWidth ? width : this.img.width / (this.img.height / height);
    let h = fitWidth ? this.img.height / (this.img.width / width) : height;

    /* add delta for parallax effect */
    h += delta;
    w = imageRatio * h;

    this.diffInHeight = h - height;
    this.node.style.backgroundSize = `${w}px ${h}px`;
  }

  update(p) {
    const {
      translateY,
      translateX,
      parallaxY
    } = this.attributes

    /* group style operations */
    if (parallaxY) {
      const px = `${Math.round(p * this.diffInHeight)}px`;
      this.node.style.backgroundPosition = `50% -${px}`;
    } 
    if (translateY || translateX) {
      const val = 0.5 - p;
      this.node.style.transform = `
        translateY(${Math.round(translateY * val)}px) 
        translateX(${Math.round(translateX * val)}px)
        translateZ(0)
      `;
    }
  }
  
}

export default Parallax;