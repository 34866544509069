import inView from 'in-view';

import CoreTemplate from './CoreTemplate';

class CatalogTemplate extends CoreTemplate {
  constructor({ el }) {
    super({ el });
    inView(el + ' .product-grid')
      .on('enter', node => node.classList.add('visible'))
      .on('exit', node => node.classList.remove('visible'))
  }
}

export default CatalogTemplate;
