/* global google */
import { GoogleSpreadsheet } from 'google-spreadsheet';

import { MAP_STYLES } from './map';

/* set center to LYON */
const HOME_GEOJSON = {
    lat: 45.750000,
    lng: 4.850000
};

const deg2rad = deg => deg * (Math.PI / 180);

const extractEmails = (text) => text.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);

export default class StoreLocator {
    constructor({
        el
    }) {
        this.section = document.querySelector(el);
        this.locator = this.section.querySelector('#locator');
        this.mapEl = this.section.querySelector('#map');
        this.locationInput = this.section.querySelector('#addressInput');
        this.searchBtn = this.section.querySelector('#search');
        this.STORES = [];
        this.loadStores()
        this.initScript();
        
        window.initMap = this.initMap.bind(this);

        this.searchBtn.addEventListener('click', () => this.search());
        this.locationInput.addEventListener('keyup', e => (e.keyCode === 13 && this.search()));

        this.openLocator = this.openLocator.bind(this);
    }

    async loadStores() {
        const doc = new GoogleSpreadsheet('16PmdPoDoLvbmWLT9H1voSvF4dudEtfE5EKKfzoMcyDo');
        doc.useApiKey('AIzaSyA8rU8aOoHkIRI2LfixRcztNxLEAYyjHZM');
        await doc.loadInfo(); // loads document properties and worksheets


        /**
         * CSV HEADER
         * nom	adresse	codePostal	ville	region	pays	telephone	url	fb	email	longitude	latitude
         */
        try {
            this.STORES = (await Promise.all(Array.from({
                length: 5
            }, (_, i) => i).map(async (i) => {
                const sheet = doc.sheetsByIndex[i];
                const rows = await sheet.getRows();
                return Promise.all(rows.map((row => ({
                        name: row.nom,
                        address: row.adresse,
                        zipcode: row.codePostal,
                        city: row.ville,
                        region: row.region,
                        country: row.pays,
                        telephone: row.telephone,
                        url: row.url,
                        fb: row.facebook,
                        email: extractEmails(row.email ?? '')?. [0],
                        lng: parseFloat(row.longitude),
                        lat: parseFloat(row.latitude)
                    }))).filter(({
                        lng,
                        lat,
                        address
                    }) => isFinite(lng) && isFinite(lat) || (address !== undefined || address !== ""))
                    .map(async (store) => {
                        if (isFinite(store.lng) && isFinite(store.lat)) return store
                        try {
                            const location = await this.inputToGeoCode(`${store.address} ${store.zipcode ?? ''} ${store.city ?? ''} ${store.country ?? ''}`);
                            return ({
                                ...store,
                                lng: location.lng(),
                                lat: location.lat()
                            })
                        } catch (e) {}
                        return store;
                    }))
            }))).flatMap(x => x);
        } catch (e) {
            console.warn('UNABLE TO LOAD STORE DATA', e)
        }
    }

    initScript() {
        const gMapsAPI = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyA8rU8aOoHkIRI2LfixRcztNxLEAYyjHZM&callback=initMap';
        const script = document.createElement('script');
        script.setAttribute('src', gMapsAPI);
        document.head.appendChild(script);
    }

    initMap() {
        this.map = new google.maps.Map(this.mapEl, {
            center: HOME_GEOJSON,
            styles: MAP_STYLES,
            zoom: 13,
            mapTypeId: 'roadmap',
            disableDefaultUI: true
        });

        this.infoWindow = new google.maps.InfoWindow();
        this.markers = [];

        this.map.addListener('bounds_changed', () => {
            if (this.map.getZoom() > 15) {
                this.map.setZoom(15);
            }
        });
    }

    inputToGeoCode(input) {
        const address = input !== undefined ? input : this.locationInput.value;
        const geocoder = new google.maps.Geocoder();

        return new Promise((resolve, reject) => {
            geocoder.geocode({
                address
            }, (results, status) => {
                if (status === google.maps.GeocoderStatus.OK) {
                    resolve(results[0].geometry.location);
                } else {
                    reject(new Error('not found'));
                }
            });
        })
    }

    createMarker({
        lat,
        lng,
        name,
        address,
        zipcode,
        city,
        country,
        url
    }) {
        let html = `
    <strong class="serif">${name}</strong><br/>
    ${address}<br />${zipcode ? zipcode : ''} ${city ? city : ''}<br />
    ${country ? country : ''}<br /><br />
    `;

        if (url) {
            html += `
        <a style="color: #313131" href="${url}" target="_BLANK">Site internet</a>
      `;
        }
        const marker = new google.maps.Marker({
            map: this.map,
            position: new google.maps.LatLng(lat, lng)
        });

        google.maps.event.addListener(marker, 'click', () => {
            this.infoWindow.setContent(html);
            this.infoWindow.open(this.map, marker);
        });

        this.markers.push(marker);
    }

    async search() {
        this.locationInput.classList.remove('error');

        try {
            const location = await this.inputToGeoCode();
            this.map.setCenter(location);
            this.cleanUp();

            /* filter out stores in radius */
            const stores = this.STORES
                .map(store => ({
                    store,
                    distance: StoreLocator.getDistanceFromLatLonInKm({
                        lat1: location.lat(),
                        lng1: location.lng(),
                        lat2: store.lat,
                        lng2: store.lng
                    })
                }))
                .filter(({
                    distance
                }) => isFinite(distance))
                .sort((a, b) => (a.distance < b.distance ? -1 : 1))
                .filter(({
                    distance
                }) => (
                    distance <= 20
                ));

            /* calculate new bounds */
            const bounds = new google.maps.LatLngBounds();
            bounds.extend(new google.maps.LatLng(location.lat(), location.lng()));

            this.STORES.forEach(store => this.createMarker(store));

            stores.forEach(({
                store
            }) => {
                const latlng = new google.maps.LatLng(store.lat, store.lng);
                bounds.extend(latlng);
            });

            this.map.fitBounds(bounds);
            this.locator.classList.add('closed');
            this.locator.addEventListener('click', this.openLocator);
        } catch (e) {
            this.locationInput.classList.add('error');
        }
    }

    openLocator() {
        this.locator.classList.remove('closed');
        this.locator.removeEventListener('click', this.openLocator);
    }

    cleanUp() {
        this.markers.forEach(marker => marker.setMap(null));
        this.markers = [];
    }

    static getDistanceFromLatLonInKm({
        lat1,
        lng1,
        lat2,
        lng2
    }) {
        /* radius of the earth in km */
        const R = 6371;
        const dLat = deg2rad(lat2 - lat1);
        const dLon = deg2rad(lng2 - lng1);
        const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);

        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        /* return distance in km */
        return R * c;
    }
}