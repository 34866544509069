export default class Menu {
  constructor({ header, nav, button, overlay }) {
    this.header = header;
    this.nav = nav;
    this.button = button;
    this.overlay = overlay;
    this.toggleOverlay = this.toggleOverlay.bind(this);
    this.toggleNavigation = this.toggleNavigation.bind(this);
    this.checkScrollPosition = this.checkScrollPosition.bind(this);

    this.setup();
    this.checkScrollPosition();

    window.addEventListener('scroll', this.checkScrollPosition);
    this.button.addEventListener('click', this.toggleNavigation);
    this.overlay.addEventListener('click', this.toggleNavigation);
  }

  setup() {
    this.header.classList.remove('overlay');
    this.nav.classList.add('hidden');
    this.overlay.classList.add('hidden');
  }

  toggleOverlay(show) {
    this.header.classList[show ? 'add' : 'remove']('overlay');
    this.button.classList[show ? 'add' : 'remove']('dark');
  }

  toggleNavigation() {
    this.nav.classList.toggle('hidden');
    this.button.classList.toggle('close');
    this.overlay.classList.toggle('hidden');
    document.body.classList.toggle('no-scroll')
  }

  checkScrollPosition() {
    const doc = document.documentElement;
    const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    this.toggleOverlay(top > 20);
  }
}