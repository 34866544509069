import CoreTemplate from './CoreTemplate';

class ProductTemplate extends CoreTemplate {
  constructor({ el }) {
    super({ el });

    this.quantityInput = this.section.querySelector('input[name="qty"]');
    this.quantityAvailable = parseInt(this.section
      .getAttribute('data-quantity'), 10);

    const quantityUp = this.section.querySelector('#quantity_up');
    const quantityDown = this.section.querySelector('#quantity_down');

    quantityUp.addEventListener('click', e => this.updateQuantity(e, 1));
    quantityDown.addEventListener('click', e => this.updateQuantity(e, -1));
  }

  updateQuantity(e, x) {
    e.preventDefault();
    const newQuantity = parseInt(this.quantityInput.value, 10) + x;
    if (newQuantity <= this.quantityAvailable && newQuantity >= 1) {
      this.quantityInput.value = newQuantity;
    }
  }
}

export default ProductTemplate;
