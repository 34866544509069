import Swiper from 'swiper';

import CoreTemplate from './CoreTemplate';

class SliderTemplate extends CoreTemplate {
  constructor({ el }) {
    super({ el });
    this.swiper = new Swiper(`${el} .swiper-container`, {
      a11y: false,
      autoplay: {
        delay: 8000
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      }
    });

    this.preloadBackgroundImages();
    
    [...this.section.querySelectorAll('[data-goto]')]
      .forEach(node => node.addEventListener('click', e => {
        e.preventDefault();
        this.swiper.slideTo(parseInt(node.getAttribute('data-goto'), 10));
      }));

    [...this.section.querySelectorAll('.control-left')]
      .forEach(node => node.addEventListener('click', () => this.swiper.slidePrev()));

    [...this.section.querySelectorAll('.control-right')]
      .forEach(node => node.addEventListener('click', () => this.swiper.slideNext()));
  }

  preloadBackgroundImages() {
    return Promise.all([
      ...this.section
      .querySelectorAll('.swiper-slide > .background')
    ]
      .map(node => {
        const imgUri = node.getAttribute('data-img');
        const img = new Image();
        const p = new Promise(resolve => {
          img.onload = resolve;
          img.onerror = resolve;
        });
        img.src = imgUri;

        return p;
      })).then(() => this.section.classList.add('loaded'));
  }
}

export default SliderTemplate;
