import inView from 'in-view';

import { createAnimation, easeOutQuad } from '../utils/animation';
import Parallax from '../utils/parallax';

/* https://gist.github.com/Warry/4254579 */

class CoreTemplate {
  constructor({ el, once = false }) {
    this.section = document.querySelector(el);
    this.active = false;
    this.onEnter = this.onEnter.bind(this);
    this.onExit = this.onExit.bind(this);
    this.onScroll = this.onScroll.bind(this);
    this.lastRequest = null;

    /* adapt scroll p value if is header-like */
    this.isFixedTop = (
      Boolean(this.section.getAttribute('data-scroll-fixed-top')) || false
    );

    this.parallax = [...this.section.querySelectorAll('.parallax')]
      .map(node => new Parallax(node));

    this.minHeightNodes = [
      ...document
        .querySelectorAll('.dynamic-min-height')
      ]
      .map(node => ({
        target: document.querySelector(node.getAttribute('data-target')),
        node
      }));

    if (once) {
      inView(el)
        .once('enter', () => { this.active = true; this.onEnter(); })
        .once('exit', () => { this.active = false; this.onExit() });
    } else {
      inView(el)
        .on('enter', () => { this.active = true; this.onEnter(); })
        .on('exit', () => { this.active = false; this.onExit() });
    }

    this.onScroll();
    this.setMinHeights();
    this.setupNextButton();

    window.addEventListener('resize', () => {
      this.onScroll();
      this.setMinHeights();
    });
  }

  onEnter() {
    this.section.classList.add('visible');
    this.onScroll();
  }

  onExit() {
    this.section.classList.remove('visible');
  }

  setupNextButton() {
    /* next button setup */
    const nextBtn = this.section.querySelector('#next-section');
    if (nextBtn) nextBtn.addEventListener('click', () => {
      const startScroll = window.scrollY;
      const targetScroll = window.scrollY - this.section.offsetHeight;
      createAnimation({
        duration: 500,
        fn: p => window.scroll(0, startScroll - targetScroll * easeOutQuad(p))
      })
    });
  }

  onScroll() {
    const { top, height } = this.section.getBoundingClientRect();
    const p = this.isFixedTop
      ? (Math.min(Math.abs(top), height) / height)
      : (Math.min(Math.abs((top + height - 150) / (height * 2 + 150)), 1));

    this.parallax.forEach(parallax => parallax.update(p));

    return true;
  }

  handleScroll() {}

  setMinHeights() {
    this.minHeightNodes.forEach(({ target, node }) => {
      const minHeight = window.innerHeight - target.offsetHeight;
      if (minHeight > 0) node.style.minHeight = minHeight + 'px';
    });
  }
}

export default CoreTemplate;
