export const easeOutQuad = t => t * (2 - t);
export const easeOutCirc = t => Math.sqrt(1 - Math.pow(t - 1, 2));
export const easeInOutQuad = t => (t < .5) ? (4 * t * t * t) : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1 /* eslint-disable-line */

export const createAnimation = ({ duration, fn = () => {} }) => {
  function wrappedAnimation({ now, start = now }) {
    if (now - start >= duration) return;
    fn(((now - start) / duration).toFixed(2));
    requestAnimationFrame(now => wrappedAnimation({ now, start }));
  }

  return requestAnimationFrame(now => wrappedAnimation({ now }));
};
