import 'regenerator-runtime/runtime';

import Menu from './menu';
import CatalogTemplate from './templates/CatalogTemplate';
import CoreTemplate from './templates/CoreTemplate';
import ProductTemplate from './templates/ProductTemplate';
import SliderTemplate from './templates/SliderTemplate';
import MultiSelectForm from './utils/form';
import StoreLocator from './utils/storeLocator';

/**
 * import * as PIXI from 'pixi.js';
 * turn off PIXI console
 * PIXI.utils.skipHello();
 */

/* templates */
/* import LiquidTemplate from './templates/LiquidTemplate'; */
/* forms */
/* google store locatr */
new Menu({
  header: document.querySelector('header'),
  nav: document.querySelector('nav'),
  button: document.querySelector('#hamburger'),
  overlay: document.querySelector('#overlay')
});

const templates = [...document.querySelectorAll('.wrapper > section.section')]
  .map(section => {
    const el = `#${section.getAttribute('id')}`;
    switch (section.getAttribute('data-template')) {
      /* case '1': */
      /*  return new LiquidTemplate({ el }); */
      case '8':
        return new SliderTemplate({ el });
      case 'catalog':
        return new CatalogTemplate({ el });
      case 'product':
        return new ProductTemplate({ el });
      case 'gmap':
        return new StoreLocator({ el });
      default:
        return new CoreTemplate({ el });
    }
  });

let lastPosition = -1;
const scrollLoop = () => {
  if (lastPosition === window.pageYOffset) {
    window.requestAnimationFrame(scrollLoop);

    return false;
  }
  lastPosition = window.pageYOffset;
  templates.forEach(template => (template.onScroll && template.onScroll()));

  return window.requestAnimationFrame(scrollLoop);
}

/* only if templates present */
if (templates.length) scrollLoop()

window.addEventListener('load', () => setTimeout(() => window.scrollTo(0, 0), 0));
if (window.history.replaceState) window.history.replaceState(null, null, window.location.href);

/* handle multi step forms */
[...document.querySelectorAll('.multi-step')].forEach(node => new MultiSelectForm({ node }));

/* handle javascript links */
[...document.querySelectorAll('[data-href]')].forEach(node => {
  node.addEventListener('click', () => {
    window.location = node.getAttribute('data-href');
  });
});

/* pop-up message */
const shouldShowMessage = (key, forceFn = () => true) => {
  const storage = (window.localStorage || window.sessionStorage);
  if (!storage) return true;
  if (storage.getItem(key) && !forceFn()) return false;

  return true;
}

const setStorageKey = (key, val) => {
  const storage = (window.localStorage || window.sessionStorage);
  if (!storage) return;
  storage.setItem(key, val);
}

window.addEventListener('load', () => {
  // const popup = document.getElementById('message-popup');
  // const closePopup = popup.getElementsByClassName('close')[0];

  const banner = document.getElementById('footer-note');
  const closeBanner = banner.getElementsByClassName('close')[0];

  // if (shouldShowMessage('munoz--message', () => [...document.body.classList].includes('index'))) {
  //   popup.classList.add('visible');
  //   closePopup.addEventListener('click', () => {
  //     popup.classList.remove('visible');
  //     setStorageKey('munoz--message', true);
  //   });

  //   popup.addEventListener('click', (e) => {
  //     if (e.target === popup) {
  //       popup.classList.remove('visible');
  //       setStorageKey('munoz--message', true);
  //     }
  //   });
  // }

  if (shouldShowMessage('munoz--footer-message', () => [...document.body.classList].includes('index'))) {
    banner.classList.add('visible');
    closeBanner.addEventListener('click', () => {
      banner.classList.remove('visible');
      setStorageKey('munoz--footer-message', true);
    });
  }
});

const createNoteRollAnimation = () => {
  const els = [...document.getElementsByClassName('note')];
  const length = els.length;
  
  const setAnimation = (target) => {
    els[target].classList.add('visible-in');
    els[(target + length - 1) % length].classList.add('hide-out');
    els[(target + length - 1) % length].classList.remove('visible-in');
    els[(target + length + 1) % length].classList.remove('hide-out');
    setTimeout(() => setAnimation((target + 1) % length), 4600);
  }

  setAnimation(0);
}

createNoteRollAnimation();